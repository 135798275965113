import { Component, ReactNode } from 'react';

import {
  Button,
  Error,
  Stack,
  Typography,
  colors,
  shippoTheme,
  styled,
} from '@goshippo/components';
import { Dialog } from '@goshippo/libraries/Dialog';

import { Translation } from 'react-i18next';

import { newRelic } from '~/src/clients/newRelic/NewRelicClient';
import { Details } from '~/src/components/Details';

type Props = {
  children?: ReactNode;
};

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  hasError: boolean;
};

const Code = styled('pre')`
  font-size: 0.8rem;
  background: ${colors.gray10};
  padding: ${shippoTheme.spacing(1, 2)};
  border-radius: 8px;
`;

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error: error.toString(),
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    newRelic.noticeError(error, { id: 'ErrorBoundary-error' });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <Dialog
        actions={[
          <Button color="secondary" key={0} onClick={() => location.reload()} variant="text">
            Refresh
          </Button>,
        ]}
        content={
          <Stack direction="column" sx={{ gap: shippoTheme.spacing(2) }}>
            <Translation>{(t) => t('errorDialog.message')}</Translation>

            <Details title={<Translation>{(t) => t('errorDialog.details')}</Translation>}>
              <Code>{this.state.error}</Code>
            </Details>
          </Stack>
        }
        title={
          <Stack alignItems="center" direction="row" spacing={shippoTheme.spacing(1)}>
            <Error color="error" />

            <Typography component="h2" variant="h2">
              <Translation>{(t) => t('errorDialog.title')}</Translation>
            </Typography>
          </Stack>
        }
        onClose={() => location.reload()}
        open
      />
    );
  }
}
