import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import { newRelic } from '~/src/clients/newRelic/NewRelicClient';
import { environment } from '~/src/utils/environment';

export const setupNewRelic = () => {
  if (environment.NEW_RELIC_ENABLED === 'false') return;

  const instance = new BrowserAgent({
    features: [JSErrors],
    info: {
      applicationID: environment.NEW_RELIC_APPLICATION_ID,
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      jsAttributes: {},
      licenseKey: environment.NEW_RELIC_LICENSE_KEY,
      sa: 1,
    },
    init: {
      ajax: { deny_list: ['bam.nr-data.net'] },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
    },
    loader_config: {
      accountID: environment.NEW_RELIC_ACCOUNT_ID,
      agentID: environment.NEW_RELIC_AGENT_ID,
      applicationID: environment.NEW_RELIC_APPLICATION_ID,
      licenseKey: environment.NEW_RELIC_LICENSE_KEY,
      trustKey: environment.NEW_RELIC_TRUST_KEY,
    },
  });

  newRelic.setInstance(instance);
};
