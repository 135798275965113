import { z } from 'zod';

export enum Environment {
  DEV_MAIN = 'dev-main',
  DEV_QA = 'dev-qa',
  LOCAL = 'local',
  PRODUCTION = 'production',
  TEST = 'test',
  TEST_E2E = 'test-e2e',
}

const BASE_SCHEMA = z
  .object({
    APP_BASE_URL: z.string().nonempty().url(),
    ENVIRONMENT_NAME: z.nativeEnum(Environment),
    LOCAL_AUTHENTICATION_ENABLED: z.literal('true').or(z.literal('false')),
    LOQATE_KEY: z.string(),
    MUI_LICENSE_KEY: z.string().nonempty(),
    SHIPPO_BASE_URL: z.string().nonempty(),
    SUPPORT_URL: z.string().nonempty().url(),
    WEB_APP_BASE_URL: z.string().nonempty().url(),
  })
  .required();

const API_SCHEMA = z
  .object({
    ARMOR_API_BASE_URL: z.string().url().nonempty(),
    DJANGO_API_BASE_URL: z.string().url().nonempty(),
    DJANGO_BASE_URL: z.string().url().nonempty(),
    SHIPPO_PUBLIC_API_BASE_URL: z.string().url().nonempty(),
    SHIPPO_REPORTS_API_BASE_URL: z.string().url().nonempty(),
  })
  .required();

/**
 * These API proxy variables are meant to be used only locally, so the application should be
 * unaware of them.
 */
const PROXY_API_SCHEMA = z
  .object({
    PROXY_ARMOR_API_BASE_URL: z.string().optional(),
    PROXY_DJANGO_API_BASE_URL: z.string().optional(),
    PROXY_DJANGO_BASE_URL: z.string().optional(),
    PROXY_SHIPPO_PUBLIC_API_BASE_URL: z.string().optional(),
    PROXY_SHIPPO_REPORTS_API_BASE_URL: z.string().optional(),
  })
  .optional();

const MICRO_FRONTEND_SCHEMA = z.object({
  MFE_TEMPLATE_URL: z.string().nonempty().url(),
});

const NEW_RELIC_SCHEMA = z.discriminatedUnion('NEW_RELIC_ENABLED', [
  z.object({
    NEW_RELIC_ACCOUNT_ID: z.string().nonempty(),
    NEW_RELIC_AGENT_ID: z.string().nonempty(),
    NEW_RELIC_APPLICATION_ID: z.string().nonempty(),
    NEW_RELIC_ENABLED: z.literal('true'),
    NEW_RELIC_LICENSE_KEY: z.string().nonempty(),
    NEW_RELIC_TRUST_KEY: z.string().nonempty(),
  }),
  z.object({
    NEW_RELIC_ENABLED: z.literal('false'),
  }),
]);

const FULL_STORY_SCHEMA = z.object({
  FULL_STORY_DEBUG: z.string().nonempty(),
  FULL_STORY_DEVMODE: z.string().nonempty(),
  FULL_STORY_HOST: z.string().nonempty(),
  FULL_STORY_NAMESPACE: z.string().nonempty(),
  FULL_STORY_ORGID: z.string().nonempty(),
  FULL_STORY_SCRIPT: z.string().nonempty(),
});

const OPTIMIZELY_SCHEMA = z.object({
  OPTIMIZELY_SDK_KEY: z.string().nonempty(),
});

const TRANSCEND_SCHEMA = z.discriminatedUnion('TRANSCEND_ENABLED', [
  z.object({
    TRANSCEND_CFA_SYNC: z.string().nonempty(),
    TRANSCEND_CSS: z.string().url().nonempty(),
    TRANSCEND_ENABLED: z.literal('true'),
    TRANSCEND_MESSAGES: z.string().url().nonempty(),
    TRANSCEND_SYNC_ENDPOINT: z.string().url().nonempty(),
    TRANSCEND_UI: z.string().url().nonempty(),
    TRANSCEND_URL: z.string().url().nonempty(),
  }),
  z.object({
    TRANSCEND_ENABLED: z.literal('false'),
  }),
]);

export const ENVIRONMENT_SCHEMA = BASE_SCHEMA.and(API_SCHEMA)
  .and(PROXY_API_SCHEMA)
  .and(MICRO_FRONTEND_SCHEMA)
  .and(NEW_RELIC_SCHEMA)
  .and(FULL_STORY_SCHEMA)
  .and(OPTIMIZELY_SCHEMA)
  .and(TRANSCEND_SCHEMA)
  .readonly();
