import { persistOverrideParamsToSession } from '@shippo/mfe-framework';

import { setupI18Next } from './i18next';
import { setupMui } from './mui';
import { setupNewRelic } from './newRelic';
import { setupTranscend } from './transcend';

export const setup = () => {
  setupNewRelic();
  setupTranscend();
  setupI18Next();
  setupMui();

  persistOverrideParamsToSession();
};
